import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import VocabularyList from "./components/VocabularyList";
import Home from "./components/Home";
import Login from "./components/Login";
import Register from "./components/Register";
import FolderList from "./components/FolderList";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Home page */}
          <Route path="/" element={<Home />} />

          {/* Authentication pages */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />

          {/* Vocabulary pages */}
          <Route path="/vocabularies" element={<VocabularyList />} />

          {/* Vocabulary list based on folder ID */}
          <Route
            path="/vocabularies/folder/:folderId"
            element={<VocabularyList />}
          />

          <Route path="/folders" element={<FolderList />} />

          {/* Example route for AppBar
          <Route path="/vocabularies/appbar" element={<ButtonAppBar />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
