import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Container,
  Paper,
  IconButton,
  Divider,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Fab,
  Card,
  CardContent,
  ListItemIcon,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { BASE_API_URL } from "../constants";
import "./FolderList.css"; // Import the updated CSS file

const FolderList = () => {
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState("add");
  const [currentFolder, setCurrentFolder] = useState(null);
  const [folderName, setFolderName] = useState("");
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false); // State to control delete confirmation dialog
  const [folderToDelete, setFolderToDelete] = useState(null); // State to track which folder is being deleted
  const navigate = useNavigate();

  useEffect(() => {
    fetchFolders();
  }, []);

  const fetchFolders = () => {
    setLoading(true);
    const token = localStorage.getItem("authToken");
    if (!token) {
      navigate("/login");
    }
    axios
      .get(`${BASE_API_URL}/folders/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setFolders(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching folders:", error);
        setLoading(false);
      });
  };

  const handleFolderClick = (folderId) => {
    navigate(`/vocabularies/folder/${folderId}`);
  };

  const handleAllVocabularyClick = () => {
    navigate(`/vocabularies`);
  };

  const handleDialogOpen = (mode, folder = null) => {
    setDialogMode(mode);
    setCurrentFolder(folder);
    setFolderName(folder ? folder.name : "");
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setCurrentFolder(null);
    setFolderName("");
  };

  const handleAddOrEditFolder = () => {
    const token = localStorage.getItem("authToken");
    if (!token) return;

    if (dialogMode === "add") {
      axios
        .post(
          `${BASE_API_URL}/folders/`,
          { name: folderName },
          { headers: { Authorization: `Token ${token}` } }
        )
        .then((response) => {
          setFolders([...folders, response.data]);
          handleDialogClose();
        })
        .catch((error) => console.error("Error adding folder:", error));
    } else if (dialogMode === "edit" && currentFolder) {
      axios
        .put(
          `${BASE_API_URL}/folders/${currentFolder.id}/`,
          { name: folderName },
          { headers: { Authorization: `Token ${token}` } }
        )
        .then((response) => {
          setFolders(
            folders.map((folder) =>
              folder.id === currentFolder.id ? response.data : folder
            )
          );
          handleDialogClose();
        })
        .catch((error) => console.error("Error editing folder:", error));
    }
  };

  const handleDeleteFolderClick = (folderId) => {
    setFolderToDelete(folderId); // Set the folder to be deleted
    setConfirmDeleteOpen(true); // Open the confirmation dialog
  };

  const confirmDeleteFolder = () => {
    const token = localStorage.getItem("authToken");
    if (!token) return;

    axios
      .delete(`${BASE_API_URL}/folders/${folderToDelete}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then(() => {
        setFolders(folders.filter((folder) => folder.id !== folderToDelete));
        setConfirmDeleteOpen(false); // Close the confirmation dialog
        setFolderToDelete(null); // Clear the folder to delete state
      })
      .catch((error) => console.error("Error deleting folder:", error));
  };

  const handleFeedbackClick = () => {
    setFeedbackOpen(true);
  };

  const handleFeedbackClose = () => {
    setFeedbackOpen(false);
    setFeedbackMessage("");
  };

  const handleFeedbackSubmit = () => {
    const email = "yh1259891725@gmail.com"; // Replace with your email address
    const subject = "Feedback from User";
    const body = encodeURIComponent(feedbackMessage);
    window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
    handleFeedbackClose();
  };

  return (
    <div className="folder-page">
      {" "}
      {/* Updated to use a full-page container */}
      <Container maxWidth="md" className="folder-container">
        <Paper elevation={4} className="folder-paper">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={3}
          >
            <Typography
              variant="h2"
              color="info"
              fontWeight={"bold"}
              ml={2}
              gutterBottom
            >
              Folder
            </Typography>
            <IconButton color="success" onClick={() => handleDialogOpen("add")}>
              <AddCircleOutlineIcon fontSize="large" />
            </IconButton>
          </Box>

          {/* All Vocabulary Link */}
          <Typography
            variant="h6"
            color="success"
            onClick={handleAllVocabularyClick}
            fontWeight={"bold"}
            sx={{
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
              transition: "color 0.3s ease", // Smooth transition effect
              mb: 2, // Add margin-bottom for spacing
              ml: 3,
            }}
          >
            All Vocabulary
          </Typography>

          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="200px"
            >
              <CircularProgress />
            </Box>
          ) : (
            <List>
              {folders.map((folder) => (
                <React.Fragment key={folder.id}>
                  <Card className="folder-card">
                    <CardContent>
                      <ListItem
                        button
                        onClick={() => handleFolderClick(folder.id)}
                        className="folder-list-item"
                      >
                        <ListItemIcon>
                          <FolderIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText
                          primary={folder.name}
                          primaryTypographyProps={{ variant: "h6" }}
                        />
                        <IconButton
                          // color="primary"
                          color="inherit"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDialogOpen("edit", folder);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="warning"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteFolderClick(folder.id); // Updated to show confirmation modal
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItem>
                    </CardContent>
                  </Card>
                  <Divider component="li" />
                </React.Fragment>
              ))}
            </List>
          )}
        </Paper>
      </Container>
      {/* Dialog for Add/Edit Folder */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>
          {dialogMode === "add" ? "Add New Folder" : "Edit Folder"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Folder Name"
            type="text"
            fullWidth
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddOrEditFolder} color="primary">
            {dialogMode === "add" ? "Add" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this folder? This action cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={confirmDeleteFolder} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Feedback Form Dialog */}
      <Dialog
        open={feedbackOpen}
        onClose={handleFeedbackClose}
        sx={{ position: "fixed", bottom: 80, right: 16 }} // Positioned at the bottom right corner
      >
        <DialogTitle>Send Feedback</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Your Feedback"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={feedbackMessage}
            onChange={(e) => setFeedbackMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFeedbackClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleFeedbackSubmit} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
      {/* Feedback Button */}
      <Fab
        color="secondary"
        aria-label="feedback"
        className="feedback-button"
        sx={{ position: "fixed", bottom: 16, right: 16 }} // Position at bottom right corner
        onClick={handleFeedbackClick}
      >
        <FeedbackIcon />
      </Fab>
    </div>
  );
};

export default FolderList;
