import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Register.css"; // Assuming you have styling
import { BASE_API_URL } from "../constants";

const Register = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  // Functions to handle input and prevent spaces
  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setUsername(value.replace(/\s/g, ""));
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value.replace(/\s/g, ""));
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value.replace(/\s/g, ""));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert("Passwords don't match");
      return;
    }

    try {
      const response = await axios.post(`${BASE_API_URL}/register/`, {
        username,
        password,
      });

      if (response.status === 201) {
        alert("Registration successful!");
        navigate("/login"); // Redirect to login after registration
      }
    } catch (error) {
      console.error("There was an error registering!", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div className="register-container">
      <div className="register-content">
        <h2>Register</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={handleUsernameChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirm-password">Confirm Password:</label>
            <input
              type="password"
              id="confirm-password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              autoComplete="off"
              required
            />
          </div>
          <button type="submit" className="btn">
            Register
          </button>
        </form>
      </div>
      {/* Contact Section */}
      <footer className="contact-footer">
        <p>
          If you encounter any issues, please contact us via{" "}
          <a href="mailto:yh1259891725@gmail.com">Email</a>
        </p>
      </footer>
    </div>
  );
};

export default Register;
