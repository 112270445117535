import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./VocabularyList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Fab, // Import Fab component
} from "@mui/material";
import FeedbackIcon from "@mui/icons-material/Feedback"; // Import Feedback icon
import ButtonAppBar from "./AppBar";
import { BASE_API_URL } from "../constants";
import { useTable, useFilters, useGlobalFilter } from "react-table";

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => (
  <span>
    Search:{" "}
    <input
      value={globalFilter || ""}
      onChange={(e) => setGlobalFilter(e.target.value || undefined)}
      placeholder="Type to search..."
      style={{
        fontSize: "1.1rem",
        margin: "0 0 10px 0",
        padding: "5px",
        width: "100%",
        maxWidth: "300px",
        borderRadius: "5px",
        border: "1px solid #ddd",
      }}
    />
  </span>
);

const GenderFilter = ({ filterValue, setFilter }) => {
  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilter(value || ""); // Update the filter value in state
  };

  return (
    <select
      value={filterValue || ""}
      onChange={handleFilterChange}
      style={{ marginTop: 5 }}
    >
      <option value="">All</option>
      {["Masculine", "Feminine", "Neuter", "Plural", "Verb", "Adj."].map(
        (gender) => (
          <option key={gender} value={gender}>
            {gender}
          </option>
        )
      )}
      <option value="Others">Others</option>
    </select>
  );
};

// Custom filter function for the "Gender" column
function genderFilter(rows, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.gender; // Access gender directly from row
    if (filterValue === "Others") {
      return (
        !["Masculine", "Feminine", "Neuter", "Plural", "Verb", "Adj."].includes(
          rowValue
        ) ||
        rowValue === undefined ||
        rowValue === null ||
        rowValue === ""
      );
    }
    return rowValue === filterValue;
  });
}

const VocabularyForm = ({
  showForm,
  setShowForm,
  handleAddVocabulary,
  genders,
  newWord,
  setNewWord,
  newTranslation,
  setNewTranslation,
  newDescription,
  setNewDescription,
  newGender,
  setNewGender,
  selectedFolder,
  setSelectedFolder,
  folders,
  handleCheckboxChange,
  resetForm,
  editingId,
}) => {
  return (
    showForm && (
      <div className="modal">
        <div className="modal-content">
          <button
            className="close-button"
            onClick={() => {
              setShowForm(false);
              resetForm();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <form onSubmit={handleAddVocabulary} className="vocabulary-form">
            <div className="form-group">
              {genders.map((gender) => (
                <div key={gender} className="checkbox-container">
                  <input
                    type="checkbox"
                    id={gender}
                    name="gender"
                    value={gender}
                    checked={gender === newGender}
                    onChange={(e) => handleCheckboxChange(e, gender)}
                    className="checkbox-input"
                  />
                  <label
                    htmlFor={gender}
                    className={`checkbox-label ${gender.toLowerCase()}`}
                  >
                    <span
                      className={`custom-checkbox ${gender.toLowerCase()}`}
                    />
                    {gender}
                  </label>
                </div>
              ))}
            </div>
            <div className="form-group">
              <label htmlFor="newWord" className="form-label">
                Word:
              </label>
              <input
                type="text"
                id="newWord"
                className="form-input"
                value={newWord}
                onChange={(e) => setNewWord(e.target.value)}
                autoComplete="off"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="newTranslation" className="form-label">
                Translation:
              </label>
              <input
                type="text"
                id="newTranslation"
                className="form-input"
                value={newTranslation}
                onChange={(e) => setNewTranslation(e.target.value)}
                autoComplete="off"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="newDescription" className="form-label">
                Description:
              </label>
              <input
                type="text"
                id="newDescription"
                className="form-input"
                value={newDescription}
                autoComplete="off"
                onChange={(e) => setNewDescription(e.target.value)}
              />
            </div>
            {/* Folder Select Input */}
            <div className="form-group">
              <label htmlFor="folder" className="form-label">
                Folder:
              </label>
              <select
                id="folder"
                value={selectedFolder}
                onChange={(e) => setSelectedFolder(e.target.value)}
                className="form-input"
                required
              >
                <option value="" disabled>
                  Select a folder
                </option>
                {folders.map((folder) => (
                  <option key={folder.id} value={folder.id}>
                    {folder.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-actions">
              <button type="submit" className="submit-button">
                {editingId ? "Update" : "Add"}
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  );
};

const VocabularyList = () => {
  const [vocabularies, setVocabularies] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [genderFilterValue, setGenderFilterValue] = useState(""); // New state for gender filter
  const [newWord, setNewWord] = useState("");
  const [newTranslation, setNewTranslation] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [newGender, setNewGender] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [editingId, setEditingId] = useState(null);
  const [hideTranslation, setHideTranslation] = useState(false);
  const [hideDescription, setHideDescription] = useState(false);
  const [hideVocab, setHideVocab] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false); // State to manage feedback form visibility
  const [feedbackMessage, setFeedbackMessage] = useState(""); // State to manage feedback message
  const genders = ["Masculine", "Feminine", "Neuter", "Plural", "Verb", "Adj."];
  const navigate = useNavigate();
  const { folderId } = useParams();

  useEffect(() => {
    setVocabularies([]);
    if (folderId) {
      fetchVocabulariesByFolder(folderId);
      setSelectedFolder(folderId);
    } else {
      fetchVocabularies();
    }
    fetchFolders();
  }, [folderId]);

  const fetchFolders = useCallback(() => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      navigate("/login");
    } else {
      axios
        .get(`${BASE_API_URL}/folders/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => setFolders(response.data))
        .catch((error) => console.error("Error fetching folders:", error));
    }
  }, [navigate]);

  const fetchVocabularies = useCallback(() => {
    const token = localStorage.getItem("authToken");
    axios
      .get(`${BASE_API_URL}/vocabularies/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setVocabularies(response.data);
        // console.log(response.data);
      })
      .catch((error) => console.error("Error fetching vocabularies:", error));
  }, []);

  const fetchVocabulariesByFolder = useCallback((folderId) => {
    const token = localStorage.getItem("authToken");
    axios
      .get(`${BASE_API_URL}/vocabularies/?folderId=${folderId}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setVocabularies(response.data);
        // console.log(response.data);
      })
      .catch((error) =>
        console.error("Error fetching vocabularies by folder:", error)
      );
  }, []);

  const handleAddVocabulary = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("authToken");
    const url = editingId
      ? `${BASE_API_URL}/vocabularies/${editingId}/`
      : `${BASE_API_URL}/vocabularies/`;
    const method = editingId ? "put" : "post";

    const genderToSend = newGender ? newGender : null;

    if (token) {
      axios[method](
        url,
        {
          word: newWord,
          translation: newTranslation,
          description: newDescription,
          gender: genderToSend,
          folder: selectedFolder,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
        .then((response) => {
          if (editingId) {
            setVocabularies((prev) =>
              prev.map((vocab) =>
                vocab.id === editingId ? response.data : vocab
              )
            );
          } else {
            setVocabularies((prev) => [...prev, response.data]);
          }
          // alert("Updated")
          resetForm();
          setShowForm(false);
        })
        .catch((error) =>
          console.error("Error adding/updating vocabulary:", error)
        );
    } else {
      console.error("No token found, unable to add vocabulary.");
    }
  };

  const handleDelete = (id) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      axios
        .delete(`${BASE_API_URL}/vocabularies/${id}/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then(() => {
          if (folderId) {
            fetchVocabulariesByFolder(folderId);
          } else {
            fetchVocabularies();
          }
        })
        .catch((error) => console.error("Error deleting vocabulary:", error));
    } else {
      console.error("No token found, unable to delete vocabulary.");
    }
  };

  const handleEditClick = (vocab) => {
    setEditingId(vocab.id);
    setNewWord(vocab.word);
    setNewTranslation(vocab.translation);
    setNewDescription(vocab.description || "");
    setNewGender(vocab.gender || "");
    setSelectedFolder(vocab.folder);
    setShowForm(true);
  };

  const resetForm = () => {
    setNewWord("");
    setNewTranslation("");
    setNewDescription("");
    setNewGender("");
    setSelectedFolder("" || folderId);
    setEditingId(null);
  };

  const handleCheckboxChange = (e, gender) => {
    if (newGender === gender) {
      setNewGender(""); // Deselect if the same gender is clicked
    } else {
      setNewGender(gender); // Select new gender
    }
  };

  const handleFeedbackClick = () => {
    setFeedbackOpen(true); // Open feedback dialog
  };

  const handleFeedbackClose = () => {
    setFeedbackOpen(false);
    setFeedbackMessage(""); // Clear feedback message
  };

  const saveFeedback = (message) => {
    const token = localStorage.getItem("authToken"); // Assuming you store the token in local storage
    axios
      .post(
        `${BASE_API_URL}/feedback/`, // Your feedback API endpoint
        { message: message },
        {
          headers: {
            Authorization: `Token ${token}`, // Authorization header with token
          },
        }
      )
      .then((response) => {
        console.log("Feedback submitted successfully:", response.data);
        // Handle successful submission (e.g., show a success message)
      })
      .catch((error) => {
        console.error("Error submitting feedback:", error);
        // Handle errors (e.g., show an error message)
      });
  };

  const handleFeedbackSubmit = () => {
    const email = "yh1259891725@gmail.com"; // Replace with your email address
    const subject = "Feedback from User: " + folders[0].username;
    const body = encodeURIComponent(feedbackMessage); // Encode the message to be URL safe
    window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
    saveFeedback(body);
    handleFeedbackClose();
  };

  const getColorByGender = (gender) => {
    switch (gender) {
      case "Masculine":
        return "blue";
      case "Feminine":
        return "red";
      case "Neuter":
        return "green";
      case "Plural":
        return "purple";
      default:
        return "black";
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div style={{ display: "flex", alignItems: "center" }}>
            German
            <button
              className="toggle-word-button"
              onClick={() => setHideVocab((prev) => !prev)}
            >
              {hideVocab ? "Show" : "Hide"}
            </button>
          </div>
        ),
        accessor: "word",
        Cell: ({ value, row }) => (
          <strong style={{ color: getColorByGender(row.original.gender) }}>
            {hideVocab ? "*****" : value}
          </strong>
        ),
      },
      {
        Header: () => (
          <div style={{ display: "flex", alignItems: "center" }}>
            Description
            <button
              className="toggle-word-button"
              onClick={() => setHideDescription((prev) => !prev)}
            >
              {hideDescription ? "Show" : "Hide"}
            </button>
          </div>
        ),
        accessor: "description",
        Cell: ({ value }) => <span>{hideDescription ? "*****" : value}</span>,
      },
      {
        Header: () => (
          <div style={{ display: "flex", alignItems: "center" }}>
            Translation
            <button
              className="toggle-translation-button"
              onClick={() => setHideTranslation((prev) => !prev)}
            >
              {hideTranslation ? "Show" : "Hide"}
            </button>
          </div>
        ),
        accessor: "translation",
        Cell: ({ value }) => <span>{hideTranslation ? "*****" : value}</span>,
      },
      {
        Header: "Filter",
        accessor: "gender",
        Filter: () => (
          <GenderFilter
            filterValue={genderFilterValue}
            setFilter={setGenderFilterValue}
          />
        ),
        filter: "includes",
        Cell: ({ value, row }) => (
          <div style={{ color: getColorByGender(row.original.gender) }}>
            {value}
          </div>
        ),
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <>
            <button
              onClick={() => handleEditClick(row.original)}
              className="edit-button"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </button>
            <button
              onClick={() => handleDelete(row.original.id)}
              className="delete-button"
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </>
        ),
      },
    ],
    [hideTranslation, hideVocab, hideDescription, genderFilterValue]
  );

  const filteredData = useMemo(() => {
    let filteredRows = [...vocabularies];

    // Apply global filter
    if (globalFilter) {
      filteredRows = filteredRows.filter((row) =>
        row.word.toLowerCase().includes(globalFilter.toLowerCase())
      );
    }

    // Apply gender filter
    if (genderFilterValue) {
      filteredRows = genderFilter(filteredRows, genderFilterValue);
    }

    return filteredRows;
  }, [vocabularies, globalFilter, genderFilterValue]);

  const tableInstance = useTable(
    { columns, data: filteredData },
    useGlobalFilter
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <div className="vocabulary-container">
      {/* Feedback Form Dialog */}
      <Dialog open={feedbackOpen} onClose={handleFeedbackClose}>
        <DialogTitle>Send Feedback</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Your Feedback"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={feedbackMessage}
            onChange={(e) => setFeedbackMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFeedbackClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleFeedbackSubmit} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>

      <div className="top-bar">
        <ButtonAppBar />
      </div>
      <GlobalFilter
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <button
        className="toggle-button-add"
        onClick={() => {
          setShowForm(!showForm);
          resetForm();
        }}
      >
        {showForm ? "Hide Form" : "Add New Vocab"}
      </button>

      <table {...getTableProps()} className="vocabulary-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  {column.render("Header")}
                  {column.Filter && <div>{column.render("Filter")}</div>}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <VocabularyForm
        showForm={showForm}
        setShowForm={setShowForm}
        handleAddVocabulary={handleAddVocabulary}
        genders={genders}
        newWord={newWord}
        setNewWord={setNewWord}
        newTranslation={newTranslation}
        setNewTranslation={setNewTranslation}
        newDescription={newDescription}
        setNewDescription={setNewDescription}
        newGender={newGender}
        setNewGender={setNewGender}
        selectedFolder={selectedFolder}
        setSelectedFolder={setSelectedFolder}
        folders={folders}
        handleCheckboxChange={handleCheckboxChange}
        resetForm={resetForm}
        editingId={editingId}
      />
      {/* Feedback Button */}
      <Fab
        color="secondary"
        aria-label="feedback"
        sx={{ position: "fixed", bottom: 16, right: 16 }} // Position at bottom right corner
        onClick={handleFeedbackClick}
      >
        <FeedbackIcon />
      </Fab>
    </div>
  );
};

export default VocabularyList;
