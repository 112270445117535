// src/constants.js

// Base URL for the backend API
// export const BASE_API_URL = "https://germanlangapp.onrender.com/api";
// export const BASE_API_URL = "http://127.0.0.1:8000/api";
export const BASE_API_URL = "https://langlearngerman.top/api";

// Authentication endpoints
export const REGISTER_URL = `${BASE_API_URL}/register/`;
export const LOGIN_URL = `${BASE_API_URL}/login/`;

// Vocabulary API endpoints
export const VOCABULARY_LIST_URL = `${BASE_API_URL}/vocabularies/`;
export const VOCABULARY_DETAIL_URL = (id) => `${BASE_API_URL}/vocabularies/${id}/`;

// Other constants
export const TOKEN_KEY = "authToken";
