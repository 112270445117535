import React from "react";
import { Link } from "react-router-dom";
import "./Home.css"; // Importing the CSS file for styling

const Home = () => {
  return (
    <div className="home-container">
      <div className="home-content">
        <h1>German LangLearning</h1>
        <p>
          Easily add new German words to your personalised vocabulary list and
          practice them effectively. Hide translations and descriptions to test
          yourself and reinforce your memory. Perfect for learners at all
          levels!
        </p>
        <div className="home-links">
          <Link to="/register" className="btn btn-register">
            Register
          </Link>
          <Link to="/login" className="btn btn-login">
            Log In
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
