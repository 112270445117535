import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Login.css";
import { BASE_API_URL } from "../constants";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${BASE_API_URL}/login/`, {
        username,
        password,
      });

      if (response.status === 200) {
        const token = response.data.token;
        // Store the token in localStorage or a global state
        localStorage.setItem("authToken", token);
        // console.log('Received token:', token);  // Debugging: Check the received token
        navigate("/folders"); // Navigate to the vocabularies page
      }
    } catch (error) {
      console.error("There was an error logging in!", error);
      alert("Invalid credentials. Please try again.");
    }
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn">
            Login
          </button>
        </form>
      </div>
      {/* Contact Section */}
      <footer className="contact-footer">
        <p>
          If you encounter any issues, please contact us via{" "}
          <a href="mailto:yh1259891725@gmail.com">Email</a>
        </p>
      </footer>
    </div>
  );
};

export default Login;
